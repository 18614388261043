import { colors } from "../assets/colors";
import { GothamMedium, GothamRegular } from "./Text";
import { JackIcons } from "../assets/jackIcons/parent";

// PROPS EXAMPLE
// const myArray = [
//   { step: "Reimbursement Name", isActive: false },
//   { step: "Upload Receipts", isActive: false },
//   { step: "Complete Data", isActive: false },
//   { step: "Recipient Details", isActive: false },
//   { step: "Confirmation", isActive: true },
// ];

export const ProgressBarJack = ({
  arrayStep,
  customLineLength = 156,
  style,
  realActiveIndex,
}) => {
  const stepLength = arrayStep?.length;

  const dotDecider = (activeIndex, index, stillActive) => {
    if (activeIndex === index || stillActive) return "#FFFFFF";
    if (index > activeIndex) return colors.greyBB;
    if (index < activeIndex) return "#343434";
    return null;
  };

  const lineColorDecider = (activeIndex, index, stillActive) => {
    if (index > activeIndex) return colors.greyea;
    if (index <= activeIndex || stillActive) return "#343434";
    return null;
  };

  return (
    <div
      style={{
        display: "flex",
        width: (stepLength - 1) * customLineLength,
        justifyContent: "center",
        ...style,
      }}
    >
      {arrayStep?.map((item, index) => {
        if (!item) return null;
        const { step, isActive, stillActive } = item;
        if (!step) return null;
        const activeIndex = arrayStep.findIndex(({ isActive }) => isActive);
        const isFirst = index === 0;

        const fontDecider = () => {
          if (typeof realActiveIndex == "number") {
            if (realActiveIndex == index) return GothamMedium;
            return GothamRegular;
          }
          if (isActive) return GothamMedium;
          return GothamRegular;
        };

        const Font = fontDecider();
        return (
          <div
            key={step}
            style={{
              display: "flex",
              position: "relative",
              alignItems: "center",
              zIndex: 2,
              width: isFirst ? "auto" : `${100 / stepLength - 1}%`,
            }}
          >
            {!isFirst && (
              <div
                style={{
                  zIndex: 0,
                  width: "100%",
                  height: 2,
                  backgroundColor: colors.greyea,
                  position: "relative",
                }}
              >
                {isActive ? (
                  <div className="stepper-active" />
                ) : (
                  <div
                    style={{
                      zIndex: 0,
                      width: "100%",
                      height: 2,
                      backgroundColor: lineColorDecider(
                        activeIndex,
                        index,
                        stillActive
                      ),
                      position: "relative",
                    }}
                  />
                )}
              </div>
            )}
            <div>
              <div
                style={{
                  backgroundColor: dotDecider(activeIndex, index, stillActive),
                  borderRadius: "100%",
                  border: (isActive || stillActive) && "2px solid #343434",
                  height: index > activeIndex ? 8 : 16,
                  width: index > activeIndex ? 8 : 16,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className={isActive && "activedot"}
              >
                {stillActive
                  ? null
                  : index < activeIndex && (
                      <JackIcons
                        name="checkmark_outline"
                        style={{ width: 12, height: 11 }}
                      />
                    )}
              </div>
              <Font
                style={{
                  fontSize: 12,
                  position: "absolute",
                  whiteSpace: "nowrap",
                  transform: "translate(-40%,0)",
                  marginTop: index > activeIndex ? 8 : 4,
                  color: index > activeIndex ? colors.greyBB : "#343434",
                }}
              >
                {step}
              </Font>
            </div>
          </div>
        );
      })}
    </div>
  );
};
