import { useRouter } from "next/router";
import { useState } from "react";
import { useForm } from "@tools";
import { colors, jackColors } from "../../../../assets/colors";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { ButtonJack } from "../../../../components/ButtonsJack/parent";
import { FileInputJack } from "../../../../components/inputs/fileInputs/fileInput";
import { ProgressBarJack } from "../../../../components/Stepper";
import { GothamMedium, GothamRegular } from "../../../../components/Text";
import { schemaGenerator } from "../../../../components/tools";
import { InvoiceErrorText } from "../../modal";
import closeBillPayment from "../../../../assets/images/close-bill-payment.svg";
import { useSubscriptionUsage } from "../../../subscriptionsJackComponents/logics/general-hooks";
import { useTranslation } from "react-i18next";
import dynamic from "next/dynamic";

const ConfirmationModalJack = dynamic(() =>
  import("../../../../components/ButtonsJack/confirmation").then(
    (mod) => mod.ConfirmationModalJack
  )
);
const CompanyLimit = dynamic(() =>
  import(
    "../../../subscriptionsJackComponents/general-components/CompanyLimit/parent"
  ).then((mod) => mod.default)
);
const OverLimit = dynamic(() =>
  import(
    "../../../subscriptionsJackComponents/general-components/OverLimit/parent"
  ).then((mod) => mod.default)
);
const UpgradeLimitBanner = dynamic(() =>
  import(
    "../../../subscriptionsJackComponents/general-components/UpgradeLimitBanner/parent"
  ).then((mod) => mod.default)
);
const OneTimeSeedModal = dynamic(() =>
  import(
    "../../../subscriptionsJackComponents/general-components/OneTimeSeedModal/parent"
  ).then((mod) => mod.default)
);

export const CloseButton = ({ onClick, style }) => (
  <div
    style={{
      width: 40,
      height: 40,
      border: "1px solid #E6E6E8",
      borderRadius: 4,
      display: "grid",
      placeItems: "center",
      ...style,
    }}
    className="darkhover"
    onClick={onClick}
  >
    <JackIcons name="close_Outline" fill="#343434" />
  </div>
);

export const InvoiceNavBar = ({
  steps,
  customLineLength = 250,
  isReimbursement = false,
}) => {
  const { push } = useRouter();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const { t: tReim } = useTranslation("reimbursement/reimbursement");
  const { t } = useTranslation("invoice-payment/invoice-payment");
  const modalTitle = isReimbursement
    ? tReim("Close Reimbursement?")
    : t("Close Bill Payment?");
  return (
    <div
      style={{
        height: "60px",
        padding: "12px 32px",
        backgroundColor: colors.whiteFC,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        zIndex: 99,
      }}
    >
      <JackIcons name="fullsize" />
      <ProgressBarJack
        arrayStep={steps}
        customLineLength={customLineLength}
        style={{}}
      />
      <div className="d-flex align-items-center">
        <GothamRegular
          className="font14"
          style={{ color: jackColors.black34, marginRight: 12 }}
        >
          {t("Close")}
        </GothamRegular>
        <CloseButton onClick={() => setOpenConfirmationModal(true)} />
      </div>
      <ConfirmationModalJack
        img={closeBillPayment}
        modal={openConfirmationModal}
        isCentered={true}
        title={modalTitle}
        text={tReim(
          "Going back means starting over and losing all progress made."
        )}
        buttonTextLeft={tReim("Cancel")}
        buttonTextRight={tReim("Close")}
        toggle={() => setOpenConfirmationModal(false)}
        onClick={() => push("/dashboard")}
      />
    </div>
  );
};

export const InvoiceUploadPage = ({ uploadQuota = 10, onUpload }) => {
  const resolver = schemaGenerator({ objectArr: ["upload_file"] });
  const useFormObj = useForm({ resolver });
  const { handleSubmit, watch, reset } = useFormObj;
  const { t } = useTranslation("invoice-payment/invoice-payment");
  const watchFile = watch("upload_file");
  const isDisabled = Boolean(!watchFile?.length);
  const maxFilesReached = Boolean(watchFile?.length > uploadQuota);

  const {
    isSeedPlan,
    invoice_qty_left,
    max_invoice_free_fee,
    loadingSubscriptionUsage,
  } = useSubscriptionUsage();
  const isV2SeedPlan = invoice_qty_left != null && isSeedPlan;
  const isOverV2SeedLimit =
    isV2SeedPlan && (watchFile?.length ?? 0) > (invoice_qty_left ?? 0);
  const isReachedV2SeedLimit =
    isV2SeedPlan && (watchFile?.length ?? 0) >= (invoice_qty_left ?? 0);
  const companyLimit = max_invoice_free_fee ?? 0;
  const usedCompanyLimit =
    (watchFile?.length ?? 0) +
    ((max_invoice_free_fee ?? 0) - (invoice_qty_left ?? 0));

  const submitInvoices = async (upload_file) => {
    onUpload(upload_file);
    reset("upload_file");
  };

  return (
    <div style={{ backgroundColor: colors.whiteFC }}>
      <div
        style={{
          minHeight: "calc(100vh - 60px)", //60px is navbar's height
          height: "auto",
          backgroundColor: colors.whiteFC,
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div style={{ width: "438px", marginTop: "52px" }}>
          <GothamMedium
            className="font24 mb-4"
            style={{ color: jackColors.black34 }}
          >
            {t("Let's upload your invoice!")}
          </GothamMedium>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "16px",
            }}
          >
            <GothamMedium style={{ color: jackColors.black34 }}>
              {t("You can upload up to 10 files.")}
            </GothamMedium>
            {isV2SeedPlan && (
              <CompanyLimit used={usedCompanyLimit} limit={companyLimit} />
            )}
          </div>
          <FileInputJack
            showLabel={false}
            useFormObj={useFormObj}
            name="upload_file"
            multiple={true}
            fileTypes={["image/png", "image/jpeg", "application/pdf"]}
            maxFiles={10}
            errorWithToaster={true}
            firstCopy={t("Browse or drag files here")}
            customError={maxFilesReached && <InvoiceErrorText />}
            middleComponent={isReachedV2SeedLimit && <UpgradeLimitBanner />}
          />
          {isOverV2SeedLimit && (
            <OverLimit
              over={(watchFile?.length ?? 0) - (invoice_qty_left ?? 0)}
            />
          )}
          <ButtonJack
            style={{ width: "calc(100%)", marginBottom: 32, marginTop: 24 }}
            onClick={() => {
              submitInvoices(watchFile);
            }}
            disabled={
              isDisabled ||
              maxFilesReached ||
              isOverV2SeedLimit ||
              loadingSubscriptionUsage
            }
          >
            {t("Continue")}
          </ButtonJack>
        </div>
      </div>
      <OneTimeSeedModal type="invoice" />
    </div>
  );
};
